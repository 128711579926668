<template>
  <message v-if="error">
    <template slot="title">{{error.title || 'Unknown error'}}</template>
    <template slot="content">{{error.message || ''}}</template>
  </message>
  <message v-else-if="noChannels">
    <template slot="title">{{$t('post.noChannelsMessage.title')}}</template>
    <span slot="content" v-html="$t('post.noChannelsMessage.content')"/>
    <a
      class="button button_type_filled"
      slot="buttons"
      href="tg://resolve?domain=ChannelQuizBot"
      target="_blank">
      {{$t('post.noChannelsMessage.button')}}
    </a>
  </message>
  <div v-else class="post__wrapper">
    <helmet>
      <title>{{$t('title.newPost')}}</title>
    </helmet>
    <div class="post__content-post">
      <div class="post__header-row">
        <h5 class="post-editor-header">
          {{$t('post.headers.appearance')}}
          <transition name="fade-200">
            <el-tooltip
              v-if="!textCorrect"
              effect="dark"
              :content="textErrorTooltipText"
              placement="bottom">
              <i class="material-icons sign sign_error">error_outline</i>
            </el-tooltip>
          </transition>
        </h5>
      </div>
      <post-media-position-switcher :position="state.mediaPosition">
        <post-quill
          class="post__quill-editor"
          v-model="state.rawText"
          :placeholder="editorPlaceholder"
          ref="quill"
          slot="text"/>

        <post-media-preview
          :show="!!(state.mediaPreviewEnabled && state.media && state.media.web_url)"
          :url="state.media && state.media.web_url"
          :type="mediaType"
          :position="state.mediaPosition"
          :can-change-position="state.mediaPosition === 'top' || text.length < 1024"
          @change-position="toggleMediaPosition"
          @remove="removeMedia"
          @load="enablePreviewByLoad"
          slot="media"/>
      </post-media-position-switcher>

      <el-collapse-transition>
        <post-media-menu
          :mediaMenuOpened.sync="mediaMenuOpened"
          :urlInputOpened.sync="urlInputOpened"
          v-bind="{
            uploadButtonLoading,
            urlButtonLoading,
          }"
          :url-input-value.sync="urlInputValue"
          v-on="{
            fileSelected: handleFileSelect,
            handleUrl,
          }"
          v-if="!(state.mediaPreviewEnabled && state.media && state.media.web_url)"/>
      </el-collapse-transition>

      <div class="post__header-row">
        <h5 class="post-editor-header">{{$t('post.headers.keyboard')}}</h5>
        <button
          class="button button_theme_hover-danger button_size_xs"
          :title="$t('post.clearKeyboard')"
          @click="clearKeyboard">
          <el-icon name="delete"/>
        </button>
      </div>

      <quiz-keyboard
        v-model="state.buttons"
        :answerCharLimit="answerCharLimit"
        :channelLanguage="selectedChannel && selectedChannel.settings && selectedChannel.settings.lang"
        :answerStatsEnabled="store.state.answerStats"/>
    </div>

    <div class="post__content-settings">
      <h5 class="post-editor-header">{{$t('post.headers.settings')}}</h5>

      <checkbox-form-item
        v-model="state.answerStats"
        :label="$t('post.settingsLabels.answerStats')"/>
      <checkbox-form-item
        v-model="state.limitAnswer"
        :label="$t('post.settingsLabels.limitAnswer')"/>
      <checkbox-form-item
        v-model="state.notifications"
        :label="$t('post.settingsLabels.notifications')"/>

      <span class="post__item" :class="{'_effect_shake': datetimePickerShake}">
        {{$t('post.settingsLabels.publish')}}
        <post-datetime-picker
          :selected-channel="selectedChannel"
          :is-scheduled="state.isScheduled"
          :scheduled-date="state.scheduledDate"
          @clear="clearPublishTime"
          @save="savePublishTime"/>
      </span>
      <post-channel-select
        class="post__channel-select"
        :selected-channel-id="state.selectedChannelId"
        :channels="channels"
        @select="state.selectedChannelId = $event"/>
      <div class="post__control-buttons">
        <button
          class="button button_type_filled button_mobile_size_lg"
          :class="{
            'button_loading': createButtonLoading,
            'post__button_theme-change': createButtonThemeChange,
            'button_theme_success': createButtonThemeSuccess,
            'button_theme_danger': createButtonThemeDanger,
            'button_effect_shake': createButtonEffectShake,
          }"
          @click="createPost">
          {{timerSet ? $t('post.actions.create') : $t('post.actions.send')}}
        </button>
        <button
          class="button post__button button_mobile_size_lg"
          :class="{
            'button_loading': previewButtonLoading,
            'post__button_theme-change': previewButtonThemeChange,
            'button_theme_success': previewButtonThemeSuccess,
            'button_theme_danger': previewButtonThemeDanger,
            'button_effect_shake': previewButtonEffectShake,
          }"
          @click="previewPost">
          {{$t('post.actions.preview')}}
        </button>
        <div class="spacer"/>
        <el-popover
          trigger="click">
          <button
            slot="reference"
            class="button button_mobile_size_lg button_icon">
            <i class="material-icons">import_export</i>
          </button>
          <div class="flex flex-col items-stretch">
            <button
              class="button button_type_filled w-full mb-1" @click="importPostShowDialog">
              {{$t('post.actions.import')}}
            </button>
            <el-popover
              trigger="click">
              <button
                slot="reference"
                class="button button_type_filled w-full">
                {{$t('post.actions.export')}}
              </button>
              <div class="flex flex-col items-stretch">
                <pre class="post__export-preview">{{JSON.stringify(this.post)}}</pre>
                <button
                  class="button button_type_filled"
                  @click="exportPostCopy">
                  {{$t('post.export.actions.copy')}}
                </button>
              </div>
            </el-popover>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
  import ElCollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  import Helmet from '@/components/Helmet.vue'
  import Message from '@/components/Message.vue'

  import QuizKeyboard from '@/components/Keyboard/QuizKeyboard.vue'

  import PostMediaPositionSwitcher from '@/components/Post/PostMediaPositionSwitcher.vue'

  const PostQuill = () => import('@/components/Post/PostQuill.vue')
  import PostMediaPreview from '@/components/Post/PostMediaPreview.vue'

  import PostMediaMenu from '@/components/Post/PostMediaMenu.vue'
  import PostDatetimePicker from '@/components/Post/PostDatetimePicker.vue'
  import PostChannelSelect from '@/components/Post/PostChannelSelect.vue'

  import CheckboxFormItem from '@/components/form/CheckboxFormItem.vue'

  import format from 'date-fns-tz/format'

  import {
    apiCreatePost,
    apiPreviewPost,
  } from '@/functions/api'

  import {
    error401,
  } from '@/mixins/handlers'

  import {
    showErrorAlert,
    nextTick,
  } from '@/mixins/utils'

  import {
    getChannelLabel,
  } from '@/functions/utils'

  import {
    showErrorSnackbar,
    reportErrorAndShowSuccessSnackbar,

    Snackbar,
  } from '@/functions/snackbar'

  import mediaMixin from '@/mixins/Post/media'
  import {
    timerMixin,
    textValidationMixin,
    formPostMixin,
    commonMixin,
    editorMixin,
  } from '@/mixins/Post/common'

  import {
    enableForMixin,
  } from '@/mixins/common'

  import {useNewpostStore} from '@/store/pinia/newpost'

  const newpostStore = useNewpostStore()

  const THEME_CHANGE_DURATION = 200
  const SUCCESS_HIGHLIGHT_DURATION = 3000
  const DANGER_HIGHLIGHT_DURATION = 3000
  const SHAKE_DURATION = 500

  import store from '@/store'
  import {chain} from '@/functions/utils'
  import {
    ensureCheckAuth,
    ensureGetChannels,
    ensureGetUser,
  } from '@/functions/data'

  export default {
    name: 'Newpost',
    mixins: [
      error401,

      showErrorAlert,
      nextTick,

      mediaMixin,
      timerMixin,
      enableForMixin,
      textValidationMixin,
      formPostMixin,
      commonMixin,
      editorMixin,
    ],
    components: {
      ElCollapseTransition,

      Helmet,
      Message,

      QuizKeyboard,

      PostQuill,
      PostMediaPreview,

      CheckboxFormItem,

      PostMediaPositionSwitcher,
      PostMediaMenu,
      PostDatetimePicker,
      PostChannelSelect,
    },
    data() {
      return {
        error: false,

        //region технические переменные
        //состояния выключателей итд
        updateButtonLoading: false,
        deleteButtonLoading: false,

        previewButtonLoading: false,
        previewButtonThemeChange: false,
        previewButtonThemeSuccess: false,
        previewButtonThemeDanger: false,
        previewButtonEffectShake: false,

        createButtonLoading: false,
        createButtonThemeChange: false,
        createButtonThemeSuccess: false,
        createButtonThemeDanger: false,
        createButtonEffectShake: false,

        datetimePickerShake: false,
        //endregion
      }
    },
    methods: {
      applyError(error) {
        this.error = error
      },

      //region drafts
      //region load
      async load() {
        return this.loadLocal()
      },
      loadLocal() {
        const draft = this.$ls.get('draft', false)

        if (!draft) {
          return false
        }
        this.updateDraft(draft)
        return true
      },
      updateDraft(draft) {
        Object.assign(newpostStore.state, draft)
        newpostStore.state.scheduledDate = new Date(draft.scheduledDate)
        newpostStore.state.mediaPreviewEnabled = !!draft.media
      },
      //endregion
      //region save
      save() {
        this.saveLocal()
      },
      saveLocal() {
        this.$ls.set('draft', newpostStore.state)
      },
      //endregion
      //endregion

      async previewPost() {
        if (!this.textCorrect) {
          return
        }

        this.previewButtonLoading = true
        const result = await apiPreviewPost(this.post)
        this.previewButtonLoading = false

        if (!result.ok) {
          this.enableFor('previewButtonThemeChange', DANGER_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
          await this.nextTick()
          this.enableFor('previewButtonThemeDanger', DANGER_HIGHLIGHT_DURATION)
          this.enableFor('previewButtonEffectShake', SHAKE_DURATION)
          return showErrorSnackbar(
            this.$t('post.errors.preview'),
            () => this.previewPost(),
            () => reportErrorAndShowSuccessSnackbar(
              'preview post',
              result.error,
              {post: this.post},
            ),
          )
        }

        this.enableFor('previewButtonThemeChange', SUCCESS_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
        await this.nextTick()
        this.enableFor('previewButtonThemeSuccess', SUCCESS_HIGHLIGHT_DURATION)
      },
      async createPost() {
        if (!this.textCorrect) {
          return
        }

        const channel_name = getChannelLabel(newpostStore.selectedChannel)

        this.updateTimerSet()
        const methodName = this.timerSet ? 'create' : 'send'

        this.$confirm(
          this.$t(`post.confirm.content.${methodName}`, {
            channel_name,
            publish_date: this.timerSet && (this.scheduleTime
              |> this.prepareDateForFormat
              |> (_ => format(_, 'dd.MM.yyyy', {timeZone: this.selectedChannelTimezone}))),
            publish_time: this.timerSet && (this.scheduleTime
              |> this.prepareDateForFormat
              |> (_ => format(_, 'HH:mm', {timeZone: this.selectedChannelTimezone}))),
          }),
          this.$t(`post.confirm.title`),
          {
            confirmButtonText: this.$t('common.yes'),
            cancelButtonText: this.$t('common.cancel'),
          },
        ).then(async () => {
          // console.log(JSON.parse(JSON.stringify(this.post)))

          this.createButtonLoading = true
          // create scheduled || send now
          let result = await apiCreatePost(this.post)
          this.createButtonLoading = false

          if (!result.ok) {
            this.enableFor('createButtonThemeChange', DANGER_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
            await this.nextTick()
            this.enableFor('createButtonThemeDanger', DANGER_HIGHLIGHT_DURATION)
            this.enableFor('createButtonEffectShake', SHAKE_DURATION)

            if (result.error.code === 401) {
              return this.error401()
            }
            if (result.error.code === 503) {
              //todo
              return
            }
            return showErrorSnackbar(
              this.$t(`post.errors.${methodName}`),
              () => this.createPost(),
              () => reportErrorAndShowSuccessSnackbar(
                'createPost',
                result.error,
                {post: this.post, id: this.id},
              ),
            )
          }

          this.enableFor('createButtonThemeChange', SUCCESS_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
          await this.nextTick()
          this.enableFor('createButtonThemeSuccess', SUCCESS_HIGHLIGHT_DURATION)

          new Snackbar({
            type: 'success',
            message: this.$t(`post.success.${methodName}`),
            timeToClose: 20000,
            closeOnAction: true,
            expanded: true,
            actions: [
              {
                type: 'router_link',
                description: this.$t('post.successActions.open'),
                to: `/post/${result.result.post.id}`,
              },
              {
                type: 'router_link',
                description: this.$t('post.successActions.edit'),
                to: `/edit/${result.result.post.id}`,
              },
            ],
          }).show()

          newpostStore.state.media = false
          newpostStore.state.rawText = ''
          newpostStore.state.isScheduled = false
        })
      },
    },
    computed: {
      noChannels() {
        return !this.channels || this.channels.length === 0
      },

      textErrorTooltipText() {
        if (this.captionTooLong) {
          return this.$t('post.textErrorTooltip.captionTooLong')
        }
        if (this.textTooLong) {
          return this.$t('post.textErrorTooltip.textTooLong')
        }
        if (this.textTooShort) {
          return this.$t('post.textErrorTooltip.textTooShort')
        }
      },
      textCorrect() {
        return !this.textTooShort && !this.textTooLong && !this.captionTooLong
      },
    },
    async mounted() {
      await this.load()

      if (newpostStore.state.selectedChannelId === -1) {
        newpostStore.state.selectedChannelId = this.channels && this.channels[0] && this.channels[0].cid || -1
      }

      this.newpostStoreUnsubscribe = newpostStore.subscribe(this.save)
    },
    beforeDestroy() {
      this.newpostStoreUnsubscribe && this.newpostStoreUnsubscribe()
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ensureGetChannels,
        ],
      )
    },
  }
</script>

<style scoped lang="stylus">
  @import "~@/components/Post/style.styl"
</style>
